module.exports = {
  home_label: "Home",
  sign_in: "Sign In",
  label_sign_rewards: "Sign in to AYANA Rewards",
  menu_loyalty_update: "Loyalty Update",
  menu_promotion: "Promotion",
  menu_member_benefit: "Member Benefits",
  menu_partner: "Partners",
  menu_reedem: "Redeem",
  menu_faq: "FAQs",
  menu_contact_us: "Contact Us",
  menu_tos: "Terms & Conditions",
  btn_book_now: "Book Now",
  label_login_sosmed: "Or sign in using",
  label_remember_me: "Remember me",
  label_forgot_password: "Forgot password?",
  btn_create_account: "Create New Account",
  placeholder_email: "Email address",
  placeholder_password: "Password",
  logout_label: "Log out",
  profile_label: "Profile",
  point_label: "POINTS",
  widget_label_arrival: "ARRIVAL",
  widget_label_departure: "DEPARTURE",
  widget_label_choose_hotel: "CHOOSE YOUR HOTEL",
  widget_label_room: "ROOMS",
  widget_label_adult: "ADULTS",
  widget_label_child: "CHILDREN",
  widget_button_cancel: "CANCEL",
  widget_dropdown_hotel_option_choose: "Choose One",
  widget_hotel_error: "Please select a hotel",
  widget_stay_error: "Please select stay date",
  become_member: "BECOME A MEMBER",
};
